import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { theme } from '../util/style'

const PageOverview = ({ children, style }) => (
  <Wrap style={style}>{children}</Wrap>
)

PageOverview.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageOverview

const Wrap = styled.div`
  color: ${theme.colors.grey3};

  text-align: left;
  font-size: 130%;
  line-height: 140%;
  @media (max-width: 800px) {
    font-size: 120%;
    line-height: 140%;
  }
  p {
    margin-top: 0%;
    margin-bottom: 1em;
    width: 90%;
  }
  a {
    color: ${theme.colors.grey3};
    border-bottom: 1px solid ${theme.colors.grey11};
    &:hover {
      color: ${theme.colors.grey1};
      border-bottom: 1px solid ${theme.colors.grey2};
    }
  }
`
