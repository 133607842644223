import React from 'react'
import styled from 'styled-components'
import { Box, Flex } from '@rebass/grid'
import RowItem from './RowItem'

const RowStack = ({ rows }) => {
  return (
    <Wrapper flexWrap={['wrap', 'nowrap']} flexDirection={'column'}>
      {rows.map(({ heading, copy, button }, i) => (
        <RowItem
          heading={heading}
          copy={copy}
          button={button}
          key={`rowstacked-${i}`}
        />
      ))}
    </Wrapper>
  )
}

export default RowStack

const Wrapper = styled(Flex)`
  width: 100%;
`
