import React from 'react'
import SEO from '../components/seo'
import { Flex, Box } from '@rebass/grid'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { theme } from '../util/style'
import { setLightboxForm } from '../actions'
import Title from '../components/page-title'

import PageHeroGallery from '../components/page-hero-gallery'
import PageButtons from '../components/page-buttons'
import Copy from '../components/page-copy'
import Row from '../components/page-content-row'
import Image from '../components/page-image-new'
import Overview from '../components/page-overview'
import PageOverview from '../components/page-overview'
import PageBanner from '../components/page-banner'
import Image1 from '../images/etc/hh-banyo-haus-AM-01.jpg'
import Image2 from '../images/etc/hh-banyo-haus-AM-010.jpg'
import Image3 from '../images/etc/hh-banyo-haus-AM-013.jpg'
import Image4 from '../images/etc/hh-banyo-haus-AM-02.jpg'
import RowStack from '../components/RowStack'
const ogImages = [
  `https://happyhaus.com.au${Image1}`,
  `https://happyhaus.com.au${Image2}`,
  `https://happyhaus.com.au${Image3}`,
  `https://happyhaus.com.au${Image4}`,
]

const leftImg = 'etc/happyhaus-post-photo-dewar-AM-18 1.png'
const rightImg =
  'etc/happyhaus-post-2020-03-20-photo-st-lucia-haus-2-2041 1.png'

const HeroImage1 = 'etc/happyhaus-gallery-photo-banyo-AM-2-18 1.png'

const slides = [
  {
    image: HeroImage1,
  },
]

const mapDispatchToProps = (dispatch) => ({})

const Page = ({ dispatch }) => {
  const click = (e, dispatch, title, redirect) => {
    e.preventDefault()
    dispatch(
      setLightboxForm({
        category: '',
        title: title,
        redirect: redirect,
      })
    )
  }

  const rowStackContent = [
    {
      heading: 'Create a Happy Haus in 2022',
      copy: 'We’ve had a big 2021 creating homes for great clients, with many more exciting projects already underway. We’re ready to connect with our next customers who are looking to commence the design process in the first quarter of 2022. Instead of “one day”, today could be “day one!”',
      button: [
        {
          label: 'Talk to us',
          onClick: (e) => {
            return click(
              e,
              dispatch,
              'Stay Inspired, keep dreaming',
              '/contact-thank-you'
            )
          },
          background: theme.colors.grey1,
        },
      ],
    },
    {
      heading: 'Take a 3D tour',
      copy: 'Sit back, relax and take the family through a 3D tour through of our recently sold Display Haus and a selection of other homes from past Open Haus’.',
      button: [
        {
          label: 'Take a tour',
          link: '/3d-tours',
          background: theme.colors.grey1,
        },
      ],
    },
    {
      heading: 'Start planning today',
      copy: `Did you know it can take up to 6 months of planning and design before commencing construction of your new home build? Whether you're considering a knockdown rebuild, have land ready to go, or you’re just learning, we have guides to start you on your journey.`,
      button: [
        {
          label: 'View our resources',
          link: '/resources',
          background: theme.colors.grey1,
        },
      ],
    },
    {
      heading: 'View Display Haus gallery',
      copy: `The Display Haus creates a striking street presence through a modern, minimal mix of materials. The design creates impressive light-filled spaces, courtesy of an internal courtyard and full-width covered outdoor room that provides natural ventilation and seamless flow between the two main living zones.`,
      button: [
        {
          label: 'View gallery',
          link: '/gallery',
          background: theme.colors.grey1,
        },
      ],
    },
  ]

  return (
    <>
      <SEO
        title="Display Haus"
        description="Experience our Display Haus, a home that engages with its
                surrounds and maximises the ability to enjoy the benefits of
                life in the subtropics."
        images={ogImages}
      />
      <Row>
        <Flex flexWrap="wrap" flexDirection={['column', 'row']}>
          <Box width={[1, 6 / 12]} px={2}>
            <PageOverview>
              <p>
                Our Display Haus has sold for $1.445M — a new record for the
                suburb! What’s next? We’ll be sharing some exciting news with
                you in 2022!
              </p>
            </PageOverview>
          </Box>
          <Box width={[1, 6 / 12]} px={2} ml={[0]}>
            <Copy>
              <p>
                If you’ve been considering a new home project there's plenty of
                ways to start your journey with us. Here are some ideas for you
                to consider if you’re wondering how.
              </p>
            </Copy>
          </Box>
        </Flex>
      </Row>

      <PageHeroGallery slides={slides} ratio={215 / 370} />
      <Row>
        <Flex flexWrap={['wrap', 'nowrap']}>
          <Box width={[1, 1]} px={2} pr={[0, 0]}>
            <RowStack rows={rowStackContent} />
          </Box>
        </Flex>
      </Row>
      <Row>
        <Flex flexWrap={['wrap', 'nowrap']}>
          <Box width={[1, 6 / 12]} px={2}>
            <Image src={leftImg} ratio={1 / 1.5} />
            <br />
            <Overview>Interview: Dewar Terrace Haus with Lachlan</Overview>
            <br />
            <Copy align="left">
              <p>
                At Happy Haus we are huge fans of single-level homes for the
                ease of living and sublime connection to the outdoors. Hear from
                Lachlan how Dewar Terrace Haus came into being and what the
                considerations and outcomes mean to him via our{' '}
                <Link to="/blog/interview-dewar-terrace-haus/">Blog</Link>.
              </p>
            </Copy>
          </Box>
          <Box width={[1, 6 / 12]} px={2}>
            <Image src={rightImg} ratio={1 / 1.5} />
            <br />
            <Overview>Stay inspired, keep dreaming</Overview>
            <br />
            <Copy align="left">
              <p>
                In our ten years we've had the pleasure of creating homes for
                many great clients in some of Australia's most amazing
                locations. With every home we keep maximising subtropical living
                front-of-mind. Get comfy and be inspired by our{' '}
                <Link to="/gallery/">Haus gallery.</Link>
              </p>
            </Copy>
          </Box>
        </Flex>
      </Row>
    </>
  )
}

export default connect(mapDispatchToProps)(Page)
