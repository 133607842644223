import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { theme, fontBold } from '../util/style'
import Row from '../components/page-content-row'
import { Flex, Box } from '@rebass/grid'
import Image1 from '../images/etc/happyhaus-gallery-photo-dewar-AM-2-2.jpg'
import PageOverview from '../components/page-overview'

class PageBanner extends Component {
  render() {
    return (
      <Row px={2}>
        <Border>
          <Flex flexWrap="wrap" flexDirection={['row', 'row']}>
            <Box width={[12 / 12, 3 / 12]} px={0} mb={-1}>
              <img src={Image1} />{' '}
            </Box>
            <Box width={[12 / 12, 9 / 12]} px={3}>
              <PageOverview>
                <Text>
                  For a limited time, you can also visit{' '}
                  <strong>Dewar Terrace Open Haus</strong> and experience the
                  epitome of single-level luxury.
                  <br />{' '}
                  <Link to="/open-haus-dewar-terrace">Find out more</Link>
                </Text>
              </PageOverview>
            </Box>
          </Flex>
        </Border>
      </Row>
    )
  }
}

export default PageBanner

const Text = styled.div`
  /* align-content: center; */
  color: ${theme.colors.grey1};
  padding-left: 30px;
  padding-top: 48px;

  @media (max-width: 800px) {
    padding-bottom: 30px;
    padding-left: 0px;
    padding-top: 20px;
    /* background: red; */
  }
  @media (max-width: 1024px) {
    padding-bottom: 19px;
    padding-left: 0px;
    padding-top: 19px;
    font-size: 80%;
  }
`

const Border = styled.div`
  border: 1px solid ${theme.colors.grey4};
  color: ${theme.colors.grey5};
  img {
    margin-bottom: -2px;
  }
`
