import React from 'react'
import styled from 'styled-components'
import { Box, Flex } from '@rebass/grid'
import Overview from '../page-overview'
import Copy from '../page-copy'
import PageButtons from '../page-buttons'
import { theme } from '../../util/style'

const RowItem = ({ heading, copy, button }) => {
  return (
    <Wrapper flexWrap={['wrap', 'nowrap']} py={[2, 3]}>
      <Box width={[1, 6 / 12]} pr={[0, 0]}>
        <Overview>
          <p>{heading}</p>
        </Overview>
      </Box>
      <Box width={[1, 6 / 12]} mr={[0, 0]} pb={[1, 2]}>
        <Copy align="left">
          <p>{copy}</p>
          <PageButtons align="left" inCopy={true} data={button} />
        </Copy>
      </Box>
    </Wrapper>
  )
}

export default RowItem

const Wrapper = styled(Flex)`
  width: 100%;
  border-bottom: 1px solid ${theme.colors.grey2};
  &:last-of-type {
    border-bottom: none;
  }
`
